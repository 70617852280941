<template>
  <div class="container">
    <rule-line
      v-if="line"
      :version="version"
      :line="line"
      @save="saveLine"
      @close="line = null"
      :outputType="rule.operator.inputType"
      :questions="questions"
      :operators="lineOperators"
      :rules="rules"
    />

    <div class="subheader">
      <template v-if="initialized">
        <div class="title" v-if="ruleId">
          <router-link
            :to="{
              name: 'rules',
              params: {
                versionId: version.id,
                sectionId: sectionId,
                ruleId: rule.id,
              },
            }"
            class="link"
          >
            #R{{ rule.id }}
          </router-link>
          - {{ originalRule.label }}
        </div>
        <div class="title" v-if="!ruleId">Nieuwe regel</div>
        <div class="buttons" v-if="enabled">
          <button
            type="button"
            class="btn btn-invisible-grey"
            @click="deleteRule"
            v-if="ruleId && ruleId !== '0'"
          >
            Verwijderen
          </button>
          <button
            type="button"
            class="btn btn-dark"
            @click="saveRule"
            :disabled="!changed"
          >
            Opslaan
          </button>
        </div>
      </template>
      <template v-if="!initialized">
        <div class="title"></div>
      </template>
    </div>

    <div class="scroller">
      <div class="center" v-if="!initialized && ruleId">
        <font-awesome-icon icon="spinner" size="2x" />
      </div>

      <div class="rule" v-if="initialized">
        <div class="field">
          <label>Sectie</label>
          <select v-model="rule.section" :disabled="!enabled">
            <option
              v-for="section in sections"
              :key="section.label"
              :value="section"
            >
              {{ section.label }}
            </option>
          </select>
        </div>

        <div class="field">
          <label>Naam</label>
          <input type="text" v-model="rule.label" :disabled="!enabled" />
        </div>

        <div class="field">
          <label>Externe waarde</label>
          <select
            v-model="rule.externalinput"
            :disabled="!enabled"
            @input="changeExternalinput"
          >
            <option :value="null"></option>
            <option
              v-for="externalinput in externalinputs"
              :key="externalinput.value"
              :value="externalinput.value"
            >
              {{ externalinput.label }}
            </option>
          </select>
        </div>

        <div class="field" v-if="!rule.externalinput">
          <label>Externe naam</label>
          <select v-model="rule.output_name" :disabled="!enabled">
            <option :value="null"></option>
            <option
              v-for="outputName in outputNames"
              :key="outputName.name"
              :value="outputName.name"
            >
              {{ outputName.label }}
            </option>
          </select>
        </div>

        <div class="field" v-if="!rule.externalinput">
          <label>Operator</label>
          <select v-model="rule.operator" :disabled="!enabled">
            <option
              v-for="operator in ruleOperators"
              :key="operator.label"
              :value="operator"
            >
              {{ operator.label }}
            </option>
          </select>
        </div>

        <div class="field" v-if="isNumeric">
          <label>Aantal decimalen</label>
          <input
            type="number"
            min="0"
            max="100"
            v-model="rule.decimals"
            :disabled="!enabled"
          />
        </div>

        <div class="field" v-if="!rule.externalinput">
          <label>Elementen</label>
          <div class="lines">
            <draggable
              class="lines-draggable"
              handle=".draghandle"
              :forceFallback="true"
              element="div"
              v-model="rule.lines"
              animation="300"
              ghostClass="ghost"
              delay="50"
              touchStartThreshold="30"
              :disabled="!enabled"
            >
              <div
                class="line-wrapper"
                v-for="(line, index) in rule.lines"
                :key="line.id"
              >
                <font-awesome-icon
                  :icon="['fas', 'arrows-alt']"
                  class="draghandle"
                  v-if="enabled"
                />
                <div
                  class="line"
                  :class="{
                    inputrule: line.input_rule,
                    inputquestion: line.input_question,
                    inputoption: line.input_option,
                    disabled: !enabled,
                    enabled: enabled,
                  }"
                  @click="openLine(line, index)"
                >
                  <template
                    v-if="
                      !line.input_rule &&
                      !line.input_question &&
                      !line.input_option
                    "
                  >
                    <div class="input">ALTIJD</div>
                    <div class="operator"></div>
                  </template>
                  <template v-if="line.input_rule && line.input_rule.section">
                    <div class="input">
                      <span @click.stop.prevent="gotoRule(line.input_rule)"
                        >#R{{ line.input_rule.id }} -
                        {{ line.input_rule.label }}</span
                      >
                    </div>
                    <div class="operator">
                      {{ line.operator.label }}
                      <template v-if="line.operator?.operandType === 'numeric'"
                        >{{ line.operand }}
                      </template>
                    </div>
                  </template>
                  <template
                    v-if="line.input_question && line.input_question.section"
                  >
                    <div class="input">
                      <span
                        @click.stop.prevent="gotoQuestion(line.input_question)"
                        >#V{{ line.input_question.id }} -
                        {{ line.input_question.text.nl }}</span
                      >
                    </div>

                    <div
                      class="operator"
                      v-if="rule.operator.inputType === 'boolean'"
                    >
                      {{ line.operator.label }}
                      <template v-if="line.operator?.operandType === 'numeric'"
                        >{{ line.operand }}
                      </template>
                    </div>
                  </template>
                  <template
                    v-if="
                      line.input_option &&
                      line.input_option.question &&
                      line.input_option.question.section
                    "
                  >
                    <div class="input">
                      <span
                        @click.stop.prevent="
                          gotoQuestion(line.input_option.question)
                        "
                        >#V{{ line.input_option.question.id }} -
                        {{ line.input_option.question.text.nl }}</span
                      >
                    </div>
                    <div class="operator">
                      {{ line.input_option.text.nl }}
                      <template v-if="line.operator?.operandType === 'numeric'"
                        >{{ line.operand }}
                      </template>
                    </div>
                  </template>
                  <div
                    class="operand score"
                    v-if="line.operator?.operandType === 'score'"
                  >
                    score: {{ line.operand }}
                  </div>
                </div>
                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  class="remove-icon"
                  @click="removeLine(index)"
                  v-if="enabled"
                />
              </div>
            </draggable>
            <div class="line-wrapper" v-if="enabled">
              <div class="line new" @click="openLine(null, rule.lines.length)">
                <font-awesome-icon class="icon" icon="plus-circle" size="2x" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="divider"
          v-if="
            rule.related_rules.length > 0 || rule.related_templates.length > 0
          "
        ></div>

        <div class="field" v-if="rule.related_rules.length > 0">
          <label>Gebruikt in regels</label>
          <div class="relateds">
            <div
              v-for="relatedRule in rule.related_rules"
              :key="relatedRule.id"
              class="related"
            >
              <router-link
                :to="{
                  name: 'rules',
                  params: {
                    versionId: version.id,
                    sectionId: relatedRule.section.id,
                    ruleId: relatedRule.id,
                  },
                }"
                >#R{{ relatedRule.id }} - {{ relatedRule.label }}
              </router-link>
              <span class="sectionref"> - {{ relatedRule.section.label }}</span>
            </div>
          </div>
        </div>

        <div class="field" v-if="rule.related_templates.length > 0">
          <label>Gebruikt in document</label>
          <div class="relateds">
            <div
              v-for="template in rule.related_templates"
              :key="template.id"
              class="related"
            >
              <router-link
                :to="{
                  name: 'templates',
                  params: {
                    versionId: version.id,
                    sectionId: template.section.id,
                    templateId: template.id,
                  },
                }"
                >#D{{ template.id }} - {{ languageValue(template.label) }}
              </router-link>
              <span class="sectionref">
                - {{ languageValue(template.section.label) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api, {
  getExternalInputs,
  getOperators,
  getRule,
  getRuleOutputNames,
  insertRule,
  updateRule,
} from '@/api';
import _, { cloneDeep } from 'lodash';
import RuleLine from '@/components/RuleLine.vue';
import Vue from 'vue';
import { supportedReportLanguages, useGetValue } from '@/languages';

const languageValue = useGetValue(supportedReportLanguages);

export default {
  components: { RuleLine },
  props: ['sectionId', 'ruleId', 'version', 'language'],
  data() {
    return {
      questions: null,
      sections: null,
      rules: null,
      rule: null,
      ruleOperators: null,
      lineOperators: null,
      flagInitialized: false,
      originalRule: null,
      popup: null,
      line: null,
      lineIndex: null,
      externalinputs: [],
      outputNames: null,
    };
  },
  created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },
  computed: {
    loaded() {
      return (
        this.rule !== null &&
        this.sections !== null &&
        this.ruleOperators !== null &&
        this.lineOperators !== null
      );
    },
    initialized() {
      return this.loaded && this.flagInitialized;
    },
    changed() {
      return !_.isEqual(this.rule, this.originalRule);
    },
    enabled() {
      return !!this.version.open;
    },
    exists() {
      return this.ruleId > 0;
    },
    isNumeric() {
      if (this.rule.externalinput) {
        const input = this.externalinputs.find(
          (i) => i.value === this.rule.externalinput
        );

        // Input is null if external inputs aren't loaded or
        // the input is missing.
        return input?.type === 'numeric';
      }

      return this.rule?.operator?.outputType === 'numeric';
    },
  },
  watch: {
    loaded() {
      if (this.loaded) {
        this.initialize();
        this.flagInitialized = true;
      } else {
        this.flagInitialized = false;
      }
    },
  },
  methods: {
    changeExternalinput(event) {
      let value = event.target.value;

      let externalinput = this.externalinputs.find((i) => i.value === value);

      if (externalinput) {
        this.rule.operator = this.ruleOperators.find(
          (o) => o.id === externalinput.operator
        );
      }
    },
    guard(next) {
      if (this.changed) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content: 'De wijzigingen in de regel zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    async load() {
      // Start some concurrent requests
      const inputsRequest = getExternalInputs();
      const namesRequest = getRuleOutputNames();

      const operators = (await getOperators()).data;
      this.ruleOperators = operators.rule_operators;
      this.lineOperators = operators.line_operators;

      this.sections = (await api.getRuleSections(this.version)).data;
      if (this.ruleId > 0) {
        this.rule = (await getRule(this.version, this.ruleId)).data;
      } else if (this.ruleId === 0 || this.ruleId === '0') {
        this.rule = {
          section: this.sections.find(
            (section) => section.id === parseInt(this.sectionId)
          ),
          lines: [],
          related_rules: [],
          related_templates: [],
          output_name: null,
          operator: this.ruleOperators[0],
        };
      }

      this.outputNames = (await namesRequest).data;

      this.externalinputs = (await inputsRequest).data;
    },
    initialize() {
      //replace section objects, topoperators, suboperators, all based on the id
      this.rule.section = this.rule.section
        ? this.sections.find((s) => s.id === this.rule.section.id)
        : null;
      this.rule.operator = this.rule.operator
        ? this.ruleOperators.find((o) => o.id === this.rule.operator.id)
        : null;
      this.rule.lines.map((line) => {
        line.operator = line.operator
          ? this.lineOperators.find((o) => o.id === line.operator.id)
          : null;
        return line;
      });
      this.originalRule = _.cloneDeep(this.rule);

      api
        .getQuestions(this.version)
        .then((response) => (this.questions = response.data));
      api
        .getRules(this.version)
        .then((response) => (this.rules = response.data));
    },
    deleteRule() {
      this.$emit('popup', {
        title: 'Regel verwijderen?',
        content: '',
        image: 'question',
        confirm: () => {
          this.$emit('popup', null);

          api
            .deleteRule(this.version, this.rule)
            .then(() => {
              this.$router.push({
                name: 'rules',
                params: {
                  versionId: this.version.id,
                  sectionId: this.rule.section.id,
                },
              });
              this.$emit('refreshMiddle');
            })
            .catch((error) => {
              this.$emit('popup', {
                title: 'Kan regel niet verwijderen',
                image: 'error',
                error: error.response.data.message,
                confirm: () => {
                  this.$emit('popup', null);
                },
              });
            });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    pushRule(rule) {
      this.$router.push({
        name: 'rules',
        params: {
          versionId: this.version.id,
          sectionId: rule.section.id,
          ruleId: rule.id,
        },
      });
    },
    async saveRule() {
      try {
        if (this.exists) {
          this.rule = (await updateRule(this.version, this.rule)).data;
          this.initialize();
          if (this.rule.section.id !== parseInt(this.sectionId)) {
            this.pushRule(this.rule);
          } else {
            this.$emit('refreshMiddle');
          }
        } else {
          this.rule = (await insertRule(this.version, this.rule)).data;
          this.originalRule = cloneDeep(this.rule);
          this.$emit('refreshMiddle');
          this.pushRule(this.rule);
        }
      } catch (e) {
        if (!e.response || e.response.status !== 422) {
          throw e;
        }

        this.$emit('popup', {
          title: 'Regel kon niet worden opgeslagen',
          image: 'error',
          error: e.response.data.message,
          confirm: () => {
            this.$emit('popup', null);
          },
          cancel: () => {
            this.$emit('popup', null);
          },
        });
      }
    },
    openLine(line, index) {
      if (!this.enabled) {
        return;
      }
      if (line) {
        this.line = _.cloneDeep(line);
      } else {
        this.line = {
          id: null,
          input_option: null,
          input_question: null,
          input_rule: null,
          operand: null,
          operator: this.lineOperators[0],
          position: null,
        };
      }

      this.lineIndex = index;
    },
    saveLine() {
      Vue.set(this.rule.lines, this.lineIndex, this.line);
      this.line = null;
      this.lineIndex = null;
    },
    removeLine(index) {
      this.rule.lines.splice(index, 1);
    },
    gotoQuestion(question) {
      this.$router.push({
        name: 'questions',
        params: {
          versionId: this.version.id,
          sectionId: question.section.id,
          questionId: question.id,
        },
      });
    },
    gotoRule(rule) {
      this.$router.push({
        name: 'rules',
        params: {
          versionId: this.version.id,
          sectionId: rule.section.id,
          ruleId: rule.id,
        },
      });
    },
    languageValue(item) {
      return languageValue(this.language, item, {});
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .scroller {
    overflow-y: auto;

    .rule {
      padding-top: 20px;
      padding-bottom: 60px;
      font-family: $font-family-gitlab;
      display: flex;
      flex-direction: column;

      .divider {
        margin: 30px 0 20px 0;
        width: 100%;
        height: 1px;
        background-color: #e4e4e4;
      }

      .field {
        display: flex;
        flex-direction: row;
        padding: 6px 0;

        label {
          width: 150px;
          text-align: right;
          color: grey;
          padding: 6px 14px 0 0;
          font-size: 14px;
        }

        textarea:disabled,
        input:disabled,
        select:disabled {
          background-color: $bg-color-disabled;
        }

        input,
        textarea,
        select {
          border-radius: 3px;
          border: 1px solid lightgrey;
          font-size: 14px;
          padding: 4px 6px;
          min-height: 24px;
          margin: 0 10px 0 0;
          outline-style: none;
        }

        input {
          width: calc(100% - 310px);
        }

        textarea {
          width: calc(100% - 270px);
          height: 8em;
          line-height: 1.4;
        }

        button {
          margin: 15px 5px;
        }

        .lines {
          width: calc(100% - 210px);

          .ghost {
            opacity: 0.4;
          }

          .line-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .draghandle {
              padding: 0 10px 0 10px;
              cursor: grab;
            }

            .remove-icon {
              cursor: pointer;
              color: $color-main;
              padding: 0 10px 0 10px;
            }

            .line.enabled:hover,
            .line.new:hover {
              background-color: $bg-color-light-grey;
            }

            .line.new {
              margin-left: 36px;
              margin-right: 34px;
              height: 17px;
              align-items: center;
              justify-content: center;
              color: rgba(0, 0, 0, 0.2);
              border-style: dashed;

              .icon {
                transform: scale(0.77);
              }
            }

            .line.disabled {
              color: rgb(84, 84, 84);
              background-color: $bg-color-disabled;

              .input {
                a,
                span {
                  color: rgb(84, 84, 84);
                }
              }
            }

            .line.enabled {
              cursor: pointer;
            }

            .line {
              font-size: 14px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 0.25rem;
              padding: 14px 30px 14px 20px;
              margin: 3px 0;

              display: flex;
              flex-direction: row;
              width: 100%;

              .input {
                width: calc(70% - 91px);
                padding: 0 10px;

                a,
                span {
                  text-decoration: none;
                  color: black;
                }

                a:hover,
                span:hover {
                  text-decoration: underline;
                  cursor: pointer;
                }
              }

              .operator {
                width: calc(30% - 39px);
                margin: 0 10px;
              }

              .operand {
                text-align: right;
                width: 70px;
                margin: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
